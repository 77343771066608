 .MuiInputBase-root {
   transition: all .5s;
   background-color: rgba(49, 240, 97, 0.32);
   padding: 0;
 }
 .MuiFormControl-root {
   background-color: rgba(49, 240, 97, 0.32);
 }

 .contact-container h2 {
   margin: 0 45%;
   min-width: 130px;
 }
 .contact-container {
   display: flex;
   flex-direction: column;
   align-items: center;
 }
 .contact-container form {
   background-color: rgba(69, 255, 71, 0.35);
   border-radius: 10px;
   margin: 30px 0;
   padding: 20px;
   width: 50vw;
   min-width: 400px;
   max-width: 600px;
   display: flex;
   flex-direction: column;
   align-items: center;
 }
