.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  display: flex;
  justify-content: center;
  position:fixed;
  background: transparent;
  width: 50%;
  height: 90vh;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 5;
}
.modal-main .piece-container img {
  max-height: 60vh;
}

.modal-main img {
  width: auto;
  max-height: 90vh;
  max-width: 90vw;
}

.display-block {
  display: block;
  z-index: 2
}

.display-none {
  display: none;
}
/* this modal creates an area around the modal-main div that can be clicked to exit the modal. The z-index puts this div below the modal-main. */
.close-modal-div {
  height: 100vh;
  width: 100vw;
  z-index: 4;
}
.close-modal-div:hover {
  cursor: alias;
  background: rgba(31, 31, 31, 0.42);
}
