.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  width: 50vw;
  height: 40px;
  border-style: none;
  background-color: blue;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0,0,0, 0.08), inset 0 1px;
  border-radius: 4px;
}
