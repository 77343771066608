.stripe-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(126, 252, 127);
  padding: 20px;
}

.payment-img {
  height: 200px;
}
