* {
  font-family: "AmaticSC";
}
.piece-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.piece-container img {
  max-height: 85vh;
  margin: 2vw;
}
.title-price-container {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  min-width: 600px;
  width: 45vw;
}
h1 {
  font-size: 45px;
  margin: 0;
}
hr {
  width: 7px;
  height: 50px;
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0.07), rgb(17, 191, 208));
  margin: 0;
}
.small-window-hr {
  background-image: linear-gradient(157deg, rgb(34, 222, 177) 20%, rgb(29, 62, 233) );
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
}
/*^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 This is the styling for table displaying the piece data
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^*/
table {
  table-layout: fixed;
  width: 100%;
  background: rgb(34, 222, 177);
  display: flex;
  flex-direction: column;
  padding: 10px;
}
tr {
  width: 100%;
  display: flex;
  height: 30px;
  margin: 5px 0;
  align-items: center;
  font-size: 18px;
}
tbody tr:hover th {
  background-color: rgba(156, 208, 7, 0.8);
  cursor: default;
}
tbody tr:hover td {
    background-color: rgba(29, 62, 233, .4);
    cursor: default;
}
tbody th {
  height: 100%;
  width: 40%;
  background: rgba(156, 208, 7, 0.5);
  box-shadow: -3px 3px 3px rgba(133, 133, 133, 0.52);
  padding: 5px;
  margin-right: 5px;
  color: rgb(69, 69, 69);
  font-weight: bold;
  text-shadow: 0 0 3px white;
}
tbody td {
  height: 100%;
  width: 60%;
  background: rgba(29, 62, 233, .3);
  box-shadow: -3px 3px 3px rgba(133, 133, 133, 0.52);
  padding: 5px;
  color: rgb(203, 203, 203);
  font-weight: bold;
  text-shadow: 0 0 3px black;
}

.piece-title-row th {
  font-size: 30px;
  background: rgb(34, 222, 177);
  text-shadow: -3px 3px 2px rgba(133, 133, 133, 0.52);
}

.piece-title-row {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
td.price-buy-cell {
  display: flex;
  justify-content: space-between;
}
td.price-buy-cell h4 {
  margin: 0;
  color: rgba(249, 254, 16, 0.86);
  text-shadow: 0 0 5px grey;
}
