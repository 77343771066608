.footer-container {
  margin: 40px 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.art-rights {
  margin: 0;
  left: 25%;
  position: absolute;
  width: 50%;
  min-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-container ul {
  display: flex;
  width: 200px;
  align-items: center;
  flex-direction: column;
  padding: 0;

}
.footer-container ul a {
  color: blue;
  margin: 0 20px;
  text-decoration: none;
  font-size: 25px;
}
.footer-container ul a:hover {
color: purple;
}
.footer-logo-container {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
}
.footer-logo-background {
  position: relative;
  background-repeat: none;
  height: 61px;
  z-index: -1;
  bottom: -39px;
}
.footer-logo {
  position: absolute;
  height: 100px;
}
.art-rights > h3 {
  width: 150px;
}
.footer-container h4 {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.reserved-rights {
  display: flex;

  text-align: center;
}
.reserved-rights h3:last-child {
  margin: 0;
}

@media only screen and (max-width: 1000px) {
  .footer-container {
    position: relative;
    flex-direction: column;
    height: 200px;
    margin-top: 140px;
  }
  .footer-container ul {
    margin: 0;
    position: relative;
    bottom: 50px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .art-rights {
    width: 100%;
    position: unset;
  }
  .footer-logo-background {
    bottom: -37px;
  }
  .reserved-rights {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
