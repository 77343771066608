* {
  box-sizing: border-box;
}
.body-container {
  min-height: 60vh;

}
.store-categories {
  height: 80vh;
  max-height: 900px;
  display: flex;
  margin: 0 1% 0 1%;
  justify-content: space-between;
}
 .card-container > h2 {
  position: absolute;
  width:100%;
  padding-left: 15px;
  font-size: 55px;
  font-family: "Petit Formal Script";
  color: white;
  text-shadow: 0 0 5px black;
  background-image: linear-gradient(150deg, rgb(255, 255, 255), rgba(255, 255, 255, 0) 70%);
  z-index: 2;
  overflow:inherit;
}
.store-categories a {
  width: 32%;
  display: flex;
  max-height: 900px;
  position: relative;
  overflow: hidden;
}
.card-container {
  width: 100%;
}

.encaustics {
  height: 100%;
  width: 100%;
  background-image: url(./assets/encaustics_card.png);
  background-size: auto;
  background-position: center;
  transition: all 2s;
}

.prints {
  height: 100%;
  width: 100%;
  background-image: url(./assets/prints_card.jpeg);
  background-size: cover;
  background-position: center;
  transition: all 3s
}
.card-container:hover .prints, .card-container:focus .prints, .card-container:hover .encaustics, .card-container:focus .encaustics, .card-container:hover .mixed, .card-container:focus .mixed {
  transform: scale(1.1) translate(10px, 40px);
  filter: grayscale(45%) contrast(200%);
}
.mixed {
  height: 100%;
  width: 100%;
  background-image: url(./assets/mixed_card.jpeg);
  background-size: cover;
  transition: all 2s;
}

@media only screen and (max-width: 1200px) {
  .store-categories {
    flex-direction: column;
    height: 60vh;
    align-items: center;
  }
  .store-categories a {
    height: 20vh;
    min-height: 100px;
    width: 90vw;
    margin-bottom: 10px;
  }
  .store-categories a h2 {
    font-size: 46px;
  }
  .card-container > div {
    background-position: center;
    background-size: cover;
  }
}
