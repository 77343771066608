@font-face {
font-family: "Petit Formal Script";
src:local('Petit Formal Script'), url('fonts/PetitFormalScript-Regular.ttf') format('truetype');
font-family: "Train One";
src:local('Train One'), url('fonts/TrainOne-Regular.ttf');
font-family: "Acme";
src:local('Acme'), url('fonts/Acme-Regular.ttf');
font-family: "AmaticSC";
src:local('AmaticSC'), url('fonts/AmaticSC-Regular.ttf', 'fonts/AmaticSC-Bold.ttf');

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
footer {
  display: flex;
  justify-content: flex-end;
  margin: 40px;
}
footer h3 {
  margin: 0 20px;
}
