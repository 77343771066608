.header-container {
  width: 25vw;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.logo-container {
  display: flex;
  height: 200px;
  width: 200px;
  justify-content: center;
}
.site-logo {
  height: 100%;
  width: 100%;
}
.logo-background {
  position: absolute;
  top: 78px;
  width: 192px;
  height: 123px;
  z-index: -1;
}
.header-container h1 {
  text-align: center;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 400px;
}
.top-bar a {
  text-decoration: none;
}
.top-bar a:active {
  color: blue;
}
